import React from "react";
import { toLower, get } from "lodash";
import Modal from "react-responsive-modal";
import Button from "../Button";
import CarryoutIcon from "../icons/Carryout.svg";
import PizzaCarryoutIcon from "../icons/PizzaCarryout.svg";
import SitdownIcon from "../icons/Sitdown.svg";
import styles from "./index.module.scss";

export default ({
  appStyles,
  T,
  onConfirm,
  show,
  onClose,
  servingOption,
  branch,
}) => {
  const servingOptionIcons = {
    pickup: appStyles.isPizza ? <PizzaCarryoutIcon /> : <CarryoutIcon />,
    sitdown: <SitdownIcon />,
  };
  const servingOptionTag = toLower(servingOption.servingOptionTag);
  return (
    <Modal
      open={show}
      onClose={onClose}
      center
      styles={{
        modal: {
          background: appStyles.cardBackgroundColor,
          borderRadius: 5,
          direction: appStyles.rtl && "rtl",
          ...(appStyles.bodyFont && { fontFamily: appStyles.bodyFont }),
          ...(appStyles.bodyFontWeight && {
            fontFamily: appStyles.bodyFontWeight,
          }),
          color: get(appStyles, "Card.color", appStyles.textColor),
        },
        overlay: { background: "rgba(0, 0, 0, 0.5)" },
      }}
      classNames={{ modal: styles.DesktopPadding }}
      blockScroll={false}
      focusTrapped
    >
      <div
        style={{ color: appStyles.actionColor }}
        className={styles.SVGWrapper}
      >
        {servingOptionIcons[servingOption.type]}
      </div>
      <div style={{ textAlign: "center" }} role="heading" aria-level="1">
        {T(
          `${T(`${T(`You are ordering`)} ${servingOptionTag} ${T("from")}`)}` +
            " " +
            branch.name,
        )}
      </div>
      <div
        style={{
          ...appStyles.StartNewOrderPopupTitle,
          textAlign: "center",
          margin: "16px 0",
          color: appStyles.accentColor,
        }}
        role="heading"
        aria-level="2"
      >
        <strong>
          {T("Please be sure to be on time to collect your order")}
        </strong>
      </div>
      <div>
        <Button
          appStyles={appStyles}
          centered
          style={{ marginBottom: 20 }}
          onClick={onConfirm}
        >
          {T("Place Order")}
        </Button>
        <Button
          linkStyle
          appStyles={appStyles}
          style={{ paddingBottom: 10, paddingTop: 10 }}
          centered
          onClick={onClose}
        >
          {T("Close")}
        </Button>
      </div>
    </Modal>
  );
};
